<script setup lang="ts">
import { ref, PropType, watch, computed } from 'vue';
import FilterIcon from '../icons/FilterIcon.vue';
import { useI18n } from 'vue-i18n';
import { DeviceFilter } from '@wision/api';
import Dropdown from '../components/Dropdown.vue';
import type { SelectedFilters } from '@wision/api';
import { useDebounceFn } from '@vueuse/core';
import ResetIcon from '../icons/ResetIcon.vue';
import SearchIcon from '../icons/SearchIcon.vue';

const { t } = useI18n();

const valueLabels: { [key in keyof DeviceFilter['categories']]: (value: string | number) => string} = {
  status: (value) => t(`unitstatus.${value}`),
  category: (value) => value as string,
  alarmTypes: (value) => t(`alarmtypes.${value}`),
  groups: (value) => value as string
};
const filterLabels: { [key in keyof DeviceFilter['categories']]: string} = {
  status: t('filter.unitstatus'),
  category: t('filter.unitcategory'),
  alarmTypes: t('filter.unitalarm'),
  groups: t('filter.unitgroups')
};

const props = defineProps({
  filter: {
    type: Object as PropType<DeviceFilter>,
    required: true,
  },
  onUpdateFilter: {
    type: Function as PropType<(filter: SelectedFilters) => void>,
    required: true,
  },
  selectedFilters: {
    type: Object as PropType<SelectedFilters>,
    required: true
  }
});

const filterOptions = ref(props.selectedFilters.categories);
const nameSearch = ref(props.selectedFilters.nameSearch ?? '');

const filtrationActive = computed(() => {
  const alarmSelected = props.filter.categories.alarmTypes.options.find(option => option.selected);
  const groupSelected = props.filter.categories.groups.options.find(option => option.selected);
  const categorySelected = props.filter.categories.category.options.find(option => option.selected);
  const statusSelected = props.filter.categories.status.options.find(option => option.selected);

  return !(!alarmSelected && !groupSelected && !categorySelected && !statusSelected);
});

const updateFilter = useDebounceFn(
  (selectedFilters: SelectedFilters) => props.onUpdateFilter(selectedFilters),
  1000
);

const handleSelectFilter = (target: HTMLInputElement, value: string | number, key: string) => {
  const currentOptions: Array<string | number> = [...filterOptions.value[key as keyof typeof filterOptions.value]];
  if (target.checked) {
    filterOptions.value = {
      ...filterOptions.value,
      [key]: [...currentOptions, value],
    };
  } else {
    const removeIndex = currentOptions.indexOf(value);
    currentOptions.splice(removeIndex, 1);

    filterOptions.value = {
      ...filterOptions.value,
      [key]: [...currentOptions],
    };
  }
};

const handleResetFilter = () => {
  filterOptions.value = Object.keys(props.filter.categories)
    .reduce((selectedFilters, currentKey) => {
      selectedFilters[currentKey as keyof typeof props.filter.categories] = [];
      return selectedFilters;
    }, {} as SelectedFilters['categories']);
  nameSearch.value = '';
};

watch([filterOptions, nameSearch], () => {
  updateFilter({
    categories: filterOptions.value,
    nameSearch: nameSearch.value
  });
});

</script>

<template>
  <Dropdown>
    <template #button>
      <button class="z-10 flex items-center justify-center text-link-inactive disabled:opacity-50">
        <FilterIcon :class="{ 'text-blue-600': filtrationActive }" />
        <span class="ml-2 capitalize">{{ t('filter.filter') }}</span>
      </button>
    </template>
    <template #default>
      <div class="h-[90vh] w-[70vw] md:h-96 grid grid-rows-[auto_55px]">
        <div class="grid grid-rows-4 md:flex flex-row flex-wrap overflow-y-hidden h-full">
          <div
            v-for="(filter, key) in props.filter.categories"
            :key="key"
            class="w-full p-2 border-b border-gray-100
            md:w-1/4 md:border-r md:border-b-0 md:px-0 h-full grid grid-rows-[2rem_auto]"
          >
            <div class="pl-2 font-medium text-center dark:text-dark-primary text-bright-primary">
              {{ filterLabels[key] }}
            </div>
            <div class="overflow-auto pr-2 scroll-bar-thin scroll-bar-small h-full">
              <div class="mb-2">
                <label
                  v-for="option in filter.options"
                  :key="option.value"
                  class="flex items-center mb-0 text-base dark:hover:bg-gray-600 hover:bg-gray-400"
                >
                  <div class="flex flex-1 pl-2 py-1 pr-3 flex-row flex-nowrap space-x-1 justify-between items-center">
                    <div class="ml-2 text-sm text-gray-900 dark:text-gray-200 grid grid-cols-[auto_1fr] gap-2">
                      <p class="truncate">{{ valueLabels[key](option.value) }}</p>
                      <p>({{ option.count }})</p>
                    </div>
                    <input
                      type="checkbox"
                      class="w-4 h-4 text-gray-600 min-w-4"
                      :checked="option.selected"
                      @input="({ target }) => handleSelectFilter(target as HTMLInputElement, option.value, key)"
                    >
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-[auto_120px] border-t border-gray-100 px-0 bg-dropdown items-center">
          <div class="relative flex-1 p-2">
            <div class="absolute inset-y-0">
              <div
                class="flex flex-col justify-center h-full ml-1 dark:text-dark-primary text-bright-primary"
              >
                <SearchIcon />
              </div>
            </div>
            <input
              v-model="nameSearch"
              class="w-full py-2 pl-10 pr-2 text-gray-800 bg-gray-200 rounded dark:bg-dropdown dark:text-gray-50"
              :placeholder="t('filter.unittext')"
            >
          </div>
          <div class="relative flex h-12 w-full items-center justify-center">
            <button
              class="z-10 flex bg-gray-600 items-center p-3 hover:bg-gray-500 rounded"
              @click="handleResetFilter"
            >
              <ResetIcon />
              <span class="ml-2 text-sm text-dark-primary">{{ t('common.restore') }}</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </Dropdown>
</template>
